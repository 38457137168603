import React from 'react';
import '../styles/Home.css';
import Slider from './Slider';
import { Link } from 'react-router-dom';


function Home() {
  return (
    <>
      <section className="home_block home_seccion-landing">
        <figure className="home_item-parallax-media">
          <div className="home_area_circulos">
            <ul className="home_circulos">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </figure>
        <div className="home_item-parallax-content home_flex-container">
          <div className="home_centered-content">
            <h1 className="home_h1">¿Quién te enseña a ser adulto?</h1>
            <p className="home_p_principal">Nadie, ¿Verdad? No te preocupes, estamos aquí para guiarte en este camino.</p>

            <div className="contenedor_home_btn">
              <h1 className="home_h2">Descarga la app</h1>
              <a href="https://play.google.com/store/apps/details?id=com.makingfriends" className="home-app-link">
                <img src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Consumibles%2Fplay-logo.png?alt=media&token=98ad72aa-e73d-48f9-a13a-4c1aa784f1c0" alt="Descargar en Google Play" />
              </a>
              <a href="https://apps.apple.com/mx/app/making-friends/id6502792919" className="home-app-link">
                <img src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Consumibles%2Fapple-store.png?alt=media&token=a608c945-b9ea-450a-9529-97f20ebc6693" alt="Descargar en App Store" />
              </a>
            </div>
          </div>
        </div>
      </section>

      <div className="home_block">
        <div id="parallax-lvl-0">
          <div id="b0-1" className="bubble size4 blanca">&nbsp;</div>
          <div id="b0-4" className="bubble size2 blanca">&nbsp;</div>
          <div id="b0-9" className="bubble size2 blanca">&nbsp;</div>
          <div id="b0-11" className="bubble size3 blanca">&nbsp;</div>
        </div>

        <div id="parallax-lvl-1">
          <div id="b1-1" className="bubble size1 blanca">&nbsp;</div>
          <div id="b1-2" className="bubble size4 rosa">&nbsp;</div>
          <div id="b1-4" className="bubble size2 blanca">&nbsp;</div>
          <div id="b1-5" className="bubble size1 blanca">&nbsp;</div>
          <div id="b1-6" className="bubble size2 rosa">&nbsp;</div>
        </div>

        <div id="parallax-lvl-2">
          <div id="b2-3" className="bubble size2 blanca">&nbsp;</div>
          <div id="b2-5" className="bubble size2 rosa">&nbsp;</div>
          <div id="b2-6" className="bubble size1 blanca">&nbsp;</div>
        </div>

        <div id="parallax-lvl-3">
          <div id="b3-1" className="bubble size2 blanca">&nbsp;</div>
          <div id="b3-3" className="bubble size1 rosa">&nbsp;</div>
          <div id="b3-4" className="bubble size4 rosa">&nbsp;</div>
          <div id="b3-6" className="bubble size2 blanca">&nbsp;</div>
        </div>

        <Slider />

      </div>

      <section className="home_texto_wrapper">
        <div className="home_texto_izquierda">
          <h2 className="home_texto_derecha_h2_1">Podrás aprender desde</h2>
        </div>
        <div className="home_texto_derecha">
          <div>
            <h2 className="home_texto_derecha_h2">Cómo usar una tarjeta de crédito</h2>
          </div>
          <div>
            <h2 className="home_texto_derecha_h2">Documentos importantes que debes tener</h2>
          </div>
          <div>
            <h2 className="home_texto_derecha_h2">Lo necesario para terminar tu tesis</h2>
          </div>
        </div>
      </section>

      <section className="IA-INU-section">
        <div className="IA-INU_centered-content">
          <h1 className="home_h1">¡Conoce nuestra nueva IA-INU!</h1>
          <p className="home_p_principal">Diseñada para ayudarte a resolver dudas y ofrecerte consejos sobre ser adulto</p>

          <div className="contenedor_home_btn">
            <img className="IA-INU-img" src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Avatar%2Favatar.png?alt=media&token=d12a3f2c-4268-4122-9207-d63a1987758b" alt="Avatar de IA-INU" />
            <Link to="/ia-inu">
              <button className="IA-INU-button">
                Saber más
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="button-icon" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </button>
              </Link>
          </div>
        </div>
      </section>

      <section className="friends-section">
        <h1 className="friends-section_texto">Con ayuda de nuestros <span className="highlight">Friends</span></h1>
        <div className="friends-section-img">
          <img src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Logos%2FGloud%204startups%201.png?alt=media&token=adc458eb-ef73-497b-9c7e-1aa99cd94b0b" alt="Logo Google Cloud" />
          <img src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Logos%2Fvexi-logo-white.png?alt=media&token=a14cb996-0b10-4311-8dbd-6cda47930af6" alt="Logo Vexi" />
        </div>

        <div className="friends-section-img">
        <img src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Logos%2FTesicaf%C3%A9Blanco.png?alt=media&token=f33ed7d4-f242-4e10-b896-44d9917011e9" alt="Logo Tesicafé" />
          <img src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Logos%2FTuinLogoBlanco.png?alt=media&token=e393b543-bf4b-4a19-a074-bb63bea44347" alt="Logo Tuin" />

        </div>
      </section>

      <section className="gif-section">
        <div className="content-wrapper">
          <h1 className="gif-section_texto">¿Quieres ser un Friend?</h1>
          <h2 className="gif-section_texto_descripcion">Captura usuarios ofreciendo tus productos como recompensas. ¡Haz crecer tu audiencia y destaca tu marca!</h2>
          <a href="https://makingfriends.typeform.com/to/ZGnLjkze" className="btn">Sí quiero</a>
        </div>
      </section>
    </>
  );
}

export default Home;
