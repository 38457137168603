import React from 'react';
import '../styles/Friends.css';

const Friends: React.FC = () => {
  return (
    <section className="Friends">
      <div className="Friends_content">
        <h1 className="Friends_h1">Invierte en el desarrollo de futuros clientes y crece junto a ellos</h1>
      </div>

      <section className="Friend_descripcion">
        <div className="Friends-item">
          <img src="https://media.giphy.com/media/YnYl5uambgXwsQ8DEs/giphy.gif?cid=ecf05e4751fhmivtvobxqxda3zlnfnnqwfu9bclo37ch11n8&ep=v1_gifs_related&rid=giphy.gif&ct=s" alt="Icono de empresa" />
          <h3>Tu empresa</h3>
          <p>Aumenta la visibilidad de tu marca entre usuarios comprometidos y motivados</p>
          <a href="https://makingfriends.typeform.com/to/ZGnLjkze" className="btn">Quiero ser un Friend</a>
        </div>
        <div className="Friends-item">
          <img src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExNzhjejBrY3RiNHBkYWdjenI0d2hudWYxMnAwMnZ1Zzk0MTlxeXZ0MCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/AFXbPMR7mj3hzzhNmQ/giphy.gif" alt="Icono de usuarios" />
          <h3>Nuestros usuarios</h3>
          <p>Aprenden a ser adulto con nuestras lecciones interactivas y ganan recompensas</p>
          <a href="https://linktr.ee/MakingFriendsMX" className="btn">Quiero ganar recompensas</a>
        </div>

      </section>

      <section className="Friends-carrucel">
        <h1 className="Friends_h1">Forma parte de nuestra red de Friends y empieza a ganar usuarios</h1>
        <div className="cards-wrapper">
          <div className="card-grid-space">
            <a className="card" href="https://startup.google.com/" >
              <div>
              <img className="Friends-logos" src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Logos%2FGloud%204startups%201.png?alt=media&token=adc458eb-ef73-497b-9c7e-1aa99cd94b0b" alt="Logo Google Cloud" />
                <p>Google nos proporciona las herramientas tecnológicas para que nuestra app y nuestra IA funcionen correctamente.</p>
              </div>
            </a>
          </div>
          <div className="card-grid-space">
            <a className="card" href="https://vexi.mx/" >
              <div>
              <img className="Friends-logos" src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Logos%2Fvexi-logo-white.png?alt=media&token=a14cb996-0b10-4311-8dbd-6cda47930af6" alt="Logo Vexi" />
                <p>A través de nuestro curso de educación financiera, Vexi ofrece a nuestros usuarios su tarjeta de crédito como recompensa.</p>
              </div>
            </a>
          </div>
          <div className="card-grid-space">
            <a className="card" href="https://www.tesicafe.com/">
              <div>
              <img className="Friends-logos" src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Logos%2FTesicaf%C3%A9Blanco.png?alt=media&token=f33ed7d4-f242-4e10-b896-44d9917011e9" alt="Logo Tesicafé" />
                <p>Nuestros usuarios aprenden a elaborar su protocolo de investigación y Tesicafé les ofrece un descuento para terminar su tesis.</p>
              </div>
            </a>
          </div>
        </div>
      </section>

      <section className="Friends-gif-section">
        <div className="Friends-content-wrapper">
          <h1 className="Friends-gif-section_texto">¿Quieres ser un Friend?</h1>
          <h2 className="Friends-gif-section_texto_descripcion">Captura usuarios ofreciendo tus productos como recompensas. ¡Haz crecer tu audiencia y destaca tu marca!</h2>
          <a href="https://makingfriends.typeform.com/to/ZGnLjkze" className="Friends-btn">Sí quiero</a>
        </div>
      </section>
      
    </section>
  );
};

export default Friends;
