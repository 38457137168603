import React from 'react';
import '../styles/IA_INU.css';

const sections = [
    {
        title: "GENERAL",
        description: "Aprende sobre documentos importantes y responsabilidades esenciales.",
        gifUrl: "https://media.giphy.com/media/Rll0YalfFXeazbuchG/giphy.gif?cid=ecf05e47ofy2bec14wwqoens52ndeuw023jbhl1wsijiq5ay&ep=v1_gifs_related&rid=giphy.gif&ct=s"
    },
    {
        title: "FISICO",
        description: "Aprender a cuidar tu cuerpo",
        gifUrl: "https://media.giphy.com/media/TDKV3w4OJzt0VpLGuV/giphy.gif?cid=ecf05e47rnestfctd9xsxttvf590ocoz8chgmbsh480qf4kq&ep=v1_gifs_related&rid=giphy.gif&ct=s"
    },
    {
        title: "EMOCIONAL",
        description: "Cuidar de tu salud mental",
        gifUrl: "https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExZGVkMGtpdjJxdzAwanU4YzMxbHpoMGliMmt1MTVnNWN4OHFwY2k2bCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/KYhrKp3o1g9VaDLPLz/giphy.gif"
    },
    {
        title: "ESPIRITUAL",
        description: "Ayudarlos a encontrar tu propósito en la vida",
        gifUrl: "https://media.giphy.com/media/Sydb3qb9SFXrqQqQ7T/giphy.gif?cid=ecf05e478mug3oiegchdxteankmmdpwhmqkkcyqel3dhgnu8&ep=v1_gifs_related&rid=giphy.gif&ct=s"
    },
    {
        title: "INTELECTUAL",
        description: "La capacidad de seguir desarrollando habilidades y conocimiento más allá de lo escolar",
        gifUrl: "https://media.giphy.com/media/pM6TTJaMSLIcMyzEZ3/giphy.gif?cid=ecf05e47o9u1g9caa1vnu3yzt2p4ykdf0dit9w06koijldtc&ep=v1_gifs_related&rid=giphy.gif&ct=s"
    },
    {
        title: "OCUPACIONAL",
        description: "Ayudarte a desarrollarte profesionalmente y en labores del hogar.",
        gifUrl: "https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExdHprOGhhbjAydmVjNGY2czI3azM5dWlwb2RhcGJla3c3ejQzNW9xaiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/KRfBgRKoKuXno1Sb4D/giphy.gif"
    },
    {
        title: "FINANCIERO",
        description: "Ayudarte a desarrollar una buena salud con el dinero.",
        gifUrl: "https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExMHF6ZXJ3ZHoxMXczeDFrNnVhMmx5ZjN1a25oaTFkcm1ta3JxdTRpOSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/fZEMkLUqLBAM1IsIAB/giphy.gif"
    },
    {
        title: "SOCIAL",
        description: "Ayudarte a desarrollar tus relaciones interpersonales.",
        gifUrl: "https://media.giphy.com/media/U8MOqP3FyBbfELsd56/giphy.gif?cid=ecf05e47p9r4ujapuz5dlgp5fow8wk42z396frwgkdghks9k&ep=v1_gifs_related&rid=giphy.gif&ct=s"
    },
    {
        title: "AMBIENTAL",
        description: "Ayudarte a mejorar tu huella en el planeta.",
        gifUrl: "https://media.giphy.com/media/IbUg1Z9i5mtnCIkBz2/giphy.gif?cid=ecf05e4759rv2znf2q0zf8xe3z8drjuq306vvjjkkmqojy2i&ep=v1_gifs_related&rid=giphy.gif&ct=s"
    }
];

const IA_INU: React.FC = () => {
    return (
        <section className="IA-INU">
            <div className="IA-INU_content">
                <img className="IA-INU-avatar" src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Avatar%2Favatar.png?alt=media&token=d12a3f2c-4268-4122-9207-d63a1987758b" alt="Avatar de IA-INU" />
                <h1 className="IA-INU_h1">¿Te imaginas tener un asesor personal para la vida adulta?</h1>
                <p className="IA-INU_p">Nuestra IA resuelve tus dudas financieras, cómo mejorar tu CV o cómo hacer una mudanza y mucho más.</p>

                <div className="contenedor_home_btn">
                    <a href="https://linktr.ee/MakingFriendsMX">
                        <button className="IA-INU-button">
                            Probar IA-INU
                            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="button-icon" viewBox="0 0 24 24">
                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                            </svg>
                        </button>
                    </a>
                </div>
            </div>

            <section className="IA-INU-Pilares">
                <h2 className="IA-INU_h2">Entrenada con las dimensiones del bienestar</h2>
                <p className="IA-INU_p">Abordando 9 aspectos fundamentales para el desarrollo integral como adultos</p>


                <div className="IA-INU-Pilares-ul">
                <ul className="centered-list">
                    {sections.map((section, index) => (
                        <li key={index}>
                            <div className="circle">
                                <img src={section.gifUrl} alt={`GIF ${index + 1}`} />
                            </div>
                            <div className="content">
                                <h3>{section.title}</h3>
                                <p>{section.description}</p>
                            </div>
                        </li>
                    ))}
                </ul>
                </div>
            </section>
        </section>
    );
};

export default IA_INU;
