import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Home from './components/Home.tsx';
import Header from './components/Header.tsx';
import Footer from './components/Footer.tsx';
import SEO from './components/SEO.tsx';
import Privacidad from './components/Privacidad.tsx';
import Terminos from './components/Terminos.tsx';
import IA_INU from './components/IA_INU.tsx';
import Friends from './components/Friends.tsx';
import ScrollToTop from './components/ScrollToTop.js';



function App() {

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <SEO />
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/privacidad" component={Privacidad} />
          <Route path="/terminos" component={Terminos} />
          <Route path="/ia-inu" component={IA_INU} />
          <Route path="/Friends" component={Friends} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
