import React, { useEffect } from 'react';
import '../styles/Slider.css';

const Slider = () => {
  useEffect(() => {
    let currentSlide = 0;
    const slides = document.querySelectorAll('.home_carousel-item');
    const totalSlides = slides.length;

    function showSlide(index) {
      slides.forEach((slide, i) => {
        slide.classList.remove('home_active');
      });

      slides[index].classList.add('home_active');
    }

    showSlide(currentSlide);

    document.getElementById('moveRight').addEventListener('click', () => {
      currentSlide = (currentSlide + 1) % totalSlides;
      showSlide(currentSlide);
    });

    document.getElementById('moveLeft').addEventListener('click', () => {
      currentSlide = (currentSlide - 1 + totalSlides) % totalSlides;
      showSlide(currentSlide);
    });
  }, []);

  return (
    <div className="home_item-parallax-content home_flex-container">
      <section className="home_carousel">
        <div className="home_carousel_nav">
          <span id="moveLeft" className="home_carousel_arrow">
            <svg className="home_carousel_icon" width="24" height="24" viewBox="0 0 24 24">
              <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"></path>
            </svg>
          </span>
          <span id="moveRight" className="home_carousel_arrow">
            <svg className="home_carousel_icon" width="24" height="24" viewBox="0 0 24 24">
              <path d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"></path>
            </svg>
          </span>
        </div>

        <div className="home_carousel-item home_carousel-item--1 home_active">
          <div className="home_carousel-item__image"></div>
          <div className="home_carousel-item__info">
            <div className="home_carousel-item__container">
              <h1 className="home_carousel-item__title">Gana recompensas</h1>
              <p className="home_carousel-item__description">Por cada curso que termines desbloquea recompensas, gracias a nuestra red de colaboradores.</p>
            </div>
          </div>
        </div>

        <div className="home_carousel-item home_carousel-item--2">
          <div className="home_carousel-item__image"></div>
          <div className="home_carousel-item__info">
            <div className="home_carousel-item__container">
              <h1 className="home_carousel-item__title">Desarrollo Integral</h1>
              <p className="home_carousel-item__description">Te ayudamos a crecer en todos los aspectos, desde el bienestar físico y emocional hasta el desarrollo profesional.</p>
            </div>
          </div>
        </div>

        <div className="home_carousel-item home_carousel-item--3">
          <div className="home_carousel-item__image"></div>
          <div className="home_carousel-item__info">
            <div className="home_carousel-item__container">
              <h1 className="home_carousel-item__title">Totalmente Gratis</h1>
              <p className="home_carousel-item__description">Accede a todos nuestros cursos sin costo alguno.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Slider;
