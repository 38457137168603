import React from 'react';
import '../styles/Header.css';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <header className="header">
      <div className="header-container">
        <a className="logo" href="/">
          <img className="home_logo" src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Logos%2FLogo.png?alt=media&token=026b329e-1ad9-41de-a69f-971bb47282e4" alt="Logo Making Friends" />
        </a>
        <nav className="nav">
          <Link className="nav-link" to="/Friends">Friends</Link>
          <Link className="nav-link" to="/ia-inu">IA-INU</Link>
        </nav>
        <a href="https://linktr.ee/MakingFriendsMX" target="_blank" rel="noopener noreferrer">
          <button className="button">
            Descargar app
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="button-icon" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </button>
        </a>
      </div>
    </header>
  );
}

export default Header;
